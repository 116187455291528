export default ({ Vue }) => {
  Vue.component('AutocompleteField', () => import('components/Shared/Forms/AutocompleteField.vue'))
  Vue.component('BooleanselectField', () => import('components/Shared/Forms/BooleanselectField.vue'))
  Vue.component('ButtonPrefer', () => import('components/Shared/Buttons/ButtonPrefer.vue'))
  Vue.component('ButtonStepPreviousNext', () => import('components/Shared/Buttons/ButtonStepPreviousNext.vue'))
  Vue.component('ButtonPrivacy', () => import('components/Shared/Buttons/ButtonPrivacy.vue'))
  Vue.component('ButtonToSyncronize', () => import('components/Shared/Buttons/ButtonToSyncronize.vue'))
  Vue.component('ListButtonTop', () => import('components/Shared/Buttons/ListButtonTop.vue'))
  Vue.component('ButtonsDevMode', () => import('components/Shared/Buttons/ButtonsDevMode.vue'))
  Vue.component('ButtonReadAllNotify', () => import('components/Shared/Buttons/ButtonReadAllNotify.vue'))
  Vue.component('SelectTypeInstallations', () => import('components/Shared/Select/SelectTypeInstallations.vue'))
  Vue.component('SelectAllSyncroInstallation', () => import('components/Shared/Select/SelectAllSyncroInstallation.vue'))
  Vue.component('StepperSupplierForms', () => import('components/Shared/Steppers/StepperSupplierForms.vue'))
  Vue.component('StepperSkeleton', () => import('components/Shared/Skeleton/StepperSkeleton.vue'))
  Vue.component('SupplierFormsSkeleton', () => import('components/Shared/Skeleton/SupplierFormsSkeleton.vue'))
  Vue.component('FilterDate', () => import('components/Shared/Filters/FilterDate.vue'))
  Vue.component('FiltersExpansion', () => import('components/Shared/Filters/FiltersExpansion.vue'))
  Vue.component('FiltersTenders', () => import('components/Shared/Filters/FiltersTenders.vue'))
  Vue.component('FiltersTendersExternal', () => import('components/Shared/Filters/FiltersTendersExternal.vue'))
  Vue.component('FiltersTenderResult', () => import('components/Shared/Filters/FiltersTenderResult.vue'))
  Vue.component('FiltersInstallations', () => import('components/Shared/Filters/FiltersInstallations.vue'))
  Vue.component('FiltersToSynchronize', () => import('components/Shared/Filters/FiltersToSynchronize.vue'))
  Vue.component('FiltersMessages', () => import('components/Shared/Filters/FiltersMessages.vue'))
  Vue.component('FilterText', () => import('components/Shared/Filters/FilterText.vue'))
  Vue.component('FilterTypeTender', () => import('components/Shared/Filters/FilterTypeTender.vue'))
  Vue.component('FilterStatusToSyncro', () => import('components/Shared/Filters/FilterStatusToSyncro.vue'))
  Vue.component('FilterStatusMessages', () => import('components/Shared/Filters/FilterStatusMessages.vue'))
  Vue.component('FilterSelectCheck', () => import('components/Shared/Filters/FilterSelectCheck.vue'))
  Vue.component('FilterRange', () => import('components/Shared/Filters/FilterRange.vue'))
  Vue.component('FilterInputTreeDialog', () => import('components/Shared/Filters/FilterInputTreeDialog.vue'))
  Vue.component('FilterReset', () => import('components/Shared/Filters/FilterReset.vue'))
  Vue.component('FilterCheckbox', () => import('components/Shared/Filters/FilterCheckbox.vue'))
  Vue.component('FilterSelect', () => import('components/Shared/Filters/FilterSelect.vue'))
  Vue.component('FilterStatusInstallations', () => import('components/Shared/Filters/FilterStatusInstallations.vue'))
  Vue.component('CategorieLPDialogTable', () => import('components/Shared/Dialogs/Table/CategorieLPDialogTable.vue'))
  Vue.component('CheckboxField', () => import('components/Shared/Forms/CheckboxField.vue'))
  Vue.component('UserStatusTender', () => import('components/Shared/Status/UserStatusTender.vue'))
  Vue.component('ChipTenderStatus', () => import('components/Shared/Status/ChipTenderStatus.vue'))
  Vue.component('ChipTipoFornituraTender', () => import('components/Shared/Status/ChipTipoFornituraTender.vue'))
  Vue.component('ChipSelectableSyncroPlatform', () => import('components/Shared/Status/ChipSelectableSyncroPlatform.vue'))
  Vue.component('InfiniteScrollGeneral', () => import('components/Shared/Scroll/InfiniteScrollGeneral.vue'))
  Vue.component('StatusAbilitation', () => import('components/Shared/Status/StatusAbilitation.vue'))
  Vue.component('StatusSyncro', () => import('components/Shared/Status/StatusSyncro.vue'))
  Vue.component('DateField', () => import('components/Shared/Forms/DateField.vue'))
  Vue.component('DateCategorieLPField', () => import('components/Shared/Forms/DateCategorieLPField.vue'))
  Vue.component('DocumentField', () => import('components/Shared/Forms/DocumentField.vue'))
  Vue.component('DocumentArt38Field', () => import('components/Shared/Forms/DocumentArt38Field.vue'))
  Vue.component('DocumentArt38Cell', () => import('components/Shared/Table/Cells/DocumentArt38Cell.vue'))
  Vue.component('DocDichiarazioniCategorieLPField', () => import('components/Shared/Forms/DocDichiarazioniCategorieLPField.vue'))
  Vue.component('DocCertificazioneSOACategorieLPField', () => import('components/Shared/Forms/DocCertificazioneSOACategorieLPField.vue'))
  Vue.component('HiddenField', () => import('components/Shared/Forms/HiddenField.vue'))
  Vue.component('LabelField', () => import('components/Shared/Forms/LabelField.vue'))
  Vue.component('RadioField', () => import('components/Shared/Forms/RadioField.vue'))
  Vue.component('SelectField', () => import('components/Shared/Forms/SelectField.vue'))
  Vue.component('NewSelectField', () => import('components/Shared/Forms/NewSelectField.vue'))
  Vue.component('WsSelectField', () => import('components/Shared/Forms/WsSelectField.vue'))
  Vue.component('SelectLivelloCategorieLPField', () => import('components/Shared/Forms/SelectLivelloCategorieLPField.vue'))
  Vue.component('BannerGeneric', () => import('components/Shared/Extra/BannerGeneric.vue'))
  Vue.component('ListFaqs', () => import('components/Shared/Lists/ListFaqs.vue'))
  Vue.component('ListVideoGuide', () => import('components/Shared/Lists/ListVideoGuide.vue'))
  Vue.component('CardVideoGuida', () => import('components/Shared/Cards/CardVideoGuida.vue'))
  Vue.component('CardTextGroupCatLP', () => import('components/Shared/Cards/CardTextGroupCatLP.vue'))
  Vue.component('CardTenders', () => import('components/Shared/Cards/CardTenders.vue'))
  Vue.component('CardTendersExternal', () => import('components/Shared/Cards/CardTendersExternal.vue'))
  Vue.component('CardTenderResult', () => import('components/Shared/Cards/CardTenderResult.vue'))
  Vue.component('CardMessages', () => import('components/Shared/Cards/CardMessages.vue'))
  Vue.component('CardHomeInstallation', () => import('components/Shared/Cards/CardHomeInstallation.vue'))
  Vue.component('CardHomeCanEnable', () => import('components/Shared/Cards/CardHomeCanEnable.vue'))
  Vue.component('CardHomeInValidation', () => import('components/Shared/Cards/CardHomeInValidation.vue'))
  Vue.component('CardHomeValidated', () => import('components/Shared/Cards/CardHomeValidated.vue'))
  Vue.component('CardHomeExpiring', () => import('components/Shared/Cards/CardHomeExpiring.vue'))
  Vue.component('CardHomeMessagesNotRead', () => import('components/Shared/Cards/CardHomeMessagesNotRead.vue'))
  Vue.component('CardInstallations', () => import('components/Shared/Cards/CardInstallations.vue'))
  Vue.component('CardCruscottoGare', () => import('components/Shared/Cards/CardCruscottoGare.vue'))
  Vue.component('CardInstallationSyncro', () => import('components/Shared/Cards/CardInstallationSyncro.vue'))
  Vue.component('ItemNotify', () => import('components/Shared/Cards/ItemNotify.vue'))
  Vue.component('TableField', () => import('components/Shared/Forms/TableField.vue'))
  Vue.component('TableContattiPrincipaliField', () => import('components/Shared/Forms/TableContattiPrincipaliField.vue'))
  Vue.component('TableCategorieLPField', () => import('components/Shared/Forms/TableCategorieLPField.vue'))
  Vue.component('TextareaField', () => import('components/Shared/Forms/TextareaField.vue'))
  Vue.component('TextField', () => import('components/Shared/Forms/TextField.vue'))
  Vue.component('EmailField', () => import('components/Shared/Forms/EmailField.vue'))
  Vue.component('NumberField', () => import('components/Shared/Forms/NumberField.vue'))
  Vue.component('PhoneNumberField', () => import('components/Shared/Forms/PhoneNumberField.vue'))
  Vue.component('YearField', () => import('components/Shared/Forms/YearField.vue'))
  Vue.component('TreeField', () => import('components/Shared/Forms/TreeField.vue'))
  Vue.component('ImportField', () => import('components/Shared/Forms/ImportField.vue'))
  Vue.component('ImportCategorieLPField', () => import('components/Shared/Forms/ImportCategorieLPField.vue'))
  Vue.component('SupplierDocumentField', () => import('components/Shared/Forms/SupplierDocumentField.vue'))
  Vue.component('CheckboxCell', () => import('components/Shared/Table/Cells/CheckboxCell.vue'))
  Vue.component('DateCell', () => import('components/Shared/Table/Cells/DateCell.vue'))
  Vue.component('UrlCell', () => import('components/Shared/Table/Cells/UrlCell.vue'))
  Vue.component('StatusSyncroCell', () => import('components/Shared/Table/Cells/StatusSyncroCell.vue'))
  Vue.component('CheckboxListSpecializzazioniCell', () => import('components/Shared/Table/Cells/CheckboxListSpecializzazioniCell.vue'))
  Vue.component('StatusCell', () => import('components/Shared/Table/Cells/StatusCell.vue'))
  Vue.component('DocumentCell', () => import('components/Shared/Table/Cells/DocumentCell.vue'))
  Vue.component('UrlTextCell', () => import('components/Shared/Table/Cells/UrlTextCell.vue'))
  Vue.component('ActionButtonCell', () => import('components/Shared/Table/Cells/ActionButtonCell.vue'))
  Vue.component('PreferCell', () => import('components/Shared/Table/Cells/PreferCell.vue'))
  Vue.component('SelectCell', () => import('components/Shared/Table/Cells/SelectCell.vue'))
  Vue.component('NewSelectCell', () => import('components/Shared/Table/Cells/NewSelectCell.vue'))
  Vue.component('SelectCheckboxCell', () => import('components/Shared/Table/Cells/SelectCheckboxCell.vue'))
  Vue.component('ImportCell', () => import('components/Shared/Table/Cells/ImportCell.vue'))
  Vue.component('NumberCell', () => import('components/Shared/Table/Cells/NumberCell.vue'))
  Vue.component('PhoneNumberCell', () => import('components/Shared/Table/Cells/PhoneNumberCell.vue'))
  Vue.component('TextCell', () => import('components/Shared/Table/Cells/TextCell.vue'))
  Vue.component('OptionGroupCell', () => import('components/Shared/Table/Cells/OptionGroupCell.vue'))
  Vue.component('PrivacyCell', () => import('components/Shared/Table/Cells/PrivacyCell.vue'))
  Vue.component('TableGeneral', () => import('components/Shared/Table/TableGeneral.vue'))
  Vue.component('GeneralDialogTable', () => import('components/Shared/Dialogs/Table/GeneralDialogTable.vue'))
  Vue.component('CertificazioniDialogTable', () => import('components/Shared/Dialogs/Table/CertificazioniDialogTable.vue'))
  Vue.component('ComponentiDialogTable', () => import('components/Shared/Dialogs/Table/ComponentiDialogTable.vue'))
  Vue.component('GestoriAmbientaliDialogTable', () => import('components/Shared/Dialogs/Table/GestoriAmbientaliDialogTable.vue'))
  Vue.component('PrestazioniProfessionaliDialogTable', () => import('components/Shared/Dialogs/Table/PrestazioniProfessionaliDialogTable.vue'))
  Vue.component('OpereDialogTable', () => import('components/Shared/Dialogs/Table/OpereDialogTable.vue'))
  Vue.component('TreeDialogTable', () => import('components/Shared/Dialogs/Table/TreeDialogTable.vue'))
  Vue.component('OperatoriList', () => import('components/Shared/Table/Lists/OperatoriList.vue'))
  Vue.component('SelectGeneral', () => import('components/Shared/Select/SelectGeneral.vue'))
  Vue.component('ServiziList', () => import('components/Shared/Table/Lists/ServiziList.vue'))
  Vue.component('PrestazioniList', () => import('components/Shared/Table/Lists/PrestazioniList.vue'))
  Vue.component('DialogNotify', () => import('components/Shared/Dialogs/DialogNotify.vue'))
  Vue.component('DialogSyncro', () => import('components/Shared/Dialogs/DialogSyncro.vue'))
  Vue.component('DialogSyncroSubscription', () => import('components/Shared/Dialogs/DialogSyncroSubscription.vue'))
  Vue.component('DialogConfirmSyncroSubscription', () => import('components/Shared/Dialogs/DialogConfirmSyncroSubscription.vue'))
  Vue.component('DialogDeSyncro', () => import('components/Shared/Dialogs/DialogDeSyncro.vue'))
  Vue.component('DialogForUserPlus', () => import('components/Shared/Dialogs/DialogForUserPlus.vue'))
  Vue.component('DialogPasswordModified', () => import('components/Shared/Dialogs/DialogPasswordModified.vue'))
  Vue.component('DialogVerifyEmail', () => import('components/Shared/Dialogs/DialogVerifyEmail.vue'))
  Vue.component('DialogCredentialSyncroErrorEmailUsed', () => import('components/Shared/Dialogs/DialogCredentialSyncroErrorEmailUsed.vue'))
  Vue.component('DialogDeleteRow', () => import('components/Shared/Dialogs/DialogDeleteRow.vue'))
  Vue.component('DialogCredentialSyncroEmailSent', () => import('components/Shared/Dialogs/DialogCredentialSyncroEmailSent.vue'))
  Vue.component('DialogCredentialSyncroComplete', () => import('components/Shared/Dialogs/DialogCredentialSyncroComplete.vue'))
  Vue.component('DialogPaperBoyNews', () => import('components/Shared/Dialogs/DialogPaperBoyNews.vue'))
  Vue.component('DialogDownloadMobileApp', () => import('components/Shared/Dialogs/DialogDownloadMobileApp.vue'))
  Vue.component('DialogPromotionalInfo', () => import('components/Shared/Dialogs/DialogPromotionalInfo.vue'))
  Vue.component('Tipologie_servizi_assistenzaList', () => import('components/Shared/Table/Lists/Tipologie_servizi_assistenzaList.vue'))
  Vue.component('CheckboxListSpecializzazioniField', () => import('components/Shared/Forms/CheckboxListSpecializzazioniField.vue'))
  Vue.component('OptionGroupField', () => import('components/Shared/Forms/OptionGroupField.vue'))
  Vue.component('PercentualeComponentiField', () => import('components/Shared/Forms/PercentualeComponentiField.vue'))
  Vue.component('TableInstallationsAvailable', () => import('components/Shared/Table/TableInstallationsAvailable.vue'))
  Vue.component('TableCanEnable', () => import('components/Shared/Table/TableCanEnable.vue'))
  Vue.component('TableValidated', () => import('components/Shared/Table/TableValidated.vue'))
  Vue.component('TableInValidation', () => import('components/Shared/Table/TableInValidation.vue'))
  Vue.component('TableExpiring', () => import('components/Shared/Table/TableExpiring.vue'))
  Vue.component('TableExpired', () => import('components/Shared/Table/TableExpired.vue'))
  Vue.component('LoadingScreen', () => import('components/Shared/LoadingScreen.vue'))
  Vue.component('TableDocumentsExpired', () => import('components/Shared/Table/TableDocumentsExpired.vue'))
  Vue.component('TableTenderPartecipation', () => import('components/Shared/Table/TableTenderPartecipation.vue'))
  Vue.component('TableTenderInvitation', () => import('components/Shared/Table/TableTenderInvitation.vue'))
  Vue.component('TableTenderPublic', () => import('components/Shared/Table/TableTenderPublic.vue'))
  Vue.component('TableTenders', () => import('components/Shared/Table/TableTenders.vue'))
  Vue.component('TableTendersExternal', () => import('components/Shared/Table/TableTendersExternal.vue'))
  Vue.component('TableTenderResult', () => import('components/Shared/Table/TableTenderResult.vue'))
  Vue.component('TableMessagesNotRead', () => import('components/Shared/Table/TableMessagesNotRead.vue'))
  Vue.component('TableMessages', () => import('components/Shared/Table/TableMessages.vue'))
  Vue.component('LoginLogout', () => import('components/Auth/LoginLogout.vue'))
  Vue.component('AvatarLogin', () => import('components/Auth/AvatarLogin.vue'))
  Vue.component('LoginRegister', () => import('components/Auth/LoginRegister.vue'))
  Vue.component('ListMenu', () => import('components/Shared/MenuLeft/ListMenu.vue'))
  Vue.component('LogoGlobe', () => import('components/Shared/Images/LogoGlobe.vue'))
  Vue.component('SelectLang', () => import('components/Shared/Select/SelectLang.vue'))
  Vue.component('ListMenuTop', () => import('components/Shared/Header/ListMenuTop.vue'))
  Vue.component('Logo', () => import('components/Shared/Header/Logo.vue'))
  Vue.component('SubscriptionExpired', () => import('components/Shared/Header/SubscriptionExpired.vue'))
  Vue.component('UpgradePlus', () => import('components/Shared/Header/UpgradePlus.vue'))
  Vue.component('LogoText', () => import('components/Shared/Images/LogoText.vue'))
  Vue.component('JwtLogin', () => import('components/Shared/Images/JwtLogin.vue'))
  Vue.component('IconReadOnly', () => import('components/Shared/Images/IconReadOnly.vue'))
  Vue.component('BackgroundAuth', () => import('components/Shared/Images/BackgroundAuth.vue'))
  Vue.component('TableInstallations', () => import('components/Shared/Table/TableInstallations.vue'))
  Vue.component('TableInstallationSyncro', () => import('components/Shared/Table/TableInstallationSyncro.vue'))
  Vue.component('ButtonReloadTableMessages', () => import('components/Shared/Buttons/ButtonReloadTableMessages.vue'))
  Vue.component('ButtonLogout', () => import('components/Shared/Buttons/ButtonLogout.vue'))
  Vue.component('ButtonUrl', () => import('components/Shared/Buttons/ButtonUrl.vue'))
  Vue.component('ButtonNotify', () => import('components/Shared/Buttons/ButtonNotify.vue'))
  Vue.component('ItemButtonManageAccount', () => import('components/Shared/Buttons/ItemButtonManageAccount.vue'))
  Vue.component('ItemButtonManageSubscription', () => import('components/Shared/Buttons/ItemButtonManageSubscription.vue'))
  Vue.component('ButtonSupport', () => import('components/Shared/Buttons/ButtonSupport.vue'))
  Vue.component('ButtonTop', () => import('components/Shared/Buttons/ButtonTop.vue'))
  Vue.component('ButtonSyncro', () => import('components/Shared/Buttons/ButtonSyncro.vue'))
  Vue.component('ButtonSyncroAccount', () => import('components/Shared/Buttons/ButtonSyncroAccount.vue'))
  Vue.component('ButtonSyncroSubscription', () => import('components/Shared/Buttons/ButtonSyncroSubscription.vue'))
  Vue.component('SelectPrefer', () => import('components/Shared/Select/SelectPrefer.vue'))
  Vue.component('TopNotify', () => import('components/Shared/Header/TopNotify.vue'))
  Vue.component('ListNotifications', () => import('components/Shared/Lists/ListNotifications.vue'))
  Vue.component('ListTerms', () => import('components/Shared/Lists/ListTerms.vue'))
  Vue.component('MenuLeft', () => import('components/Shared/MenuLeft/MenuLeft.vue'))
  Vue.component('Footer', () => import('components/Shared/Footer/Footer.vue'))
  Vue.component('Header', () => import('components/Shared/Header/Header.vue'))
  Vue.component('PageHeader', () => import('pages/PageHeader.vue'))
  Vue.component('BottomTable', () => import('components/Shared/Table/Components/BottomTable.vue'))
  Vue.component('TopTable', () => import('components/Shared/Table/Components/TopTable.vue'))
  Vue.component('NoDataTable', () => import('components/Shared/Table/Components/NoDataTable.vue'))
  Vue.component('PopupCellTable', () => import('components/Shared/Table/Components/PopupCellTable.vue'))
  Vue.component('PaginationTable', () => import('components/Shared/Table/Components/PaginationTable.vue'))
  Vue.component('ButtonsPaginationTable', () => import('components/Shared/Table/Components/ButtonsPaginationTable.vue'))
  Vue.component('BodyTable', () => import('components/Shared/Table/Components/BodyTable.vue'))
  Vue.component('BodyTableMessages', () => import('components/Shared/Table/Components/BodyTableMessages.vue'))
  Vue.component('AllDialogs', () => import('components/Shared/Dialogs/AllDialogs.vue'))
  // Vue.component('HeaderTable', import('components/Shared/Table/Components/HeaderTable.vue'))
}
