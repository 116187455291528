import { i18n } from 'boot/i18n'
import { Loading } from 'quasar'
import helpers from 'mixins/mixin-helpers'

export default ({ router, store }) => {
  /**
   * Set route guard
   */
  router.beforeEach((to, from, next) => {
    if (from.path === '/') {
      Loading.show()
    }
    // Pagina senza bisogno di login
    if (!to.matched.find(route => route.meta.auth)) {
      if (to.path === i18n.t('routes.auth')) {
        store.dispatch('auth/getSessionStatus').then((sessionStatus) => {
          if (sessionStatus) {
            next({
              path: i18n.t('routes.home'),
              replace: true
            })
          } else {
            Loading.hide()
            next()
          }
        })
      } else {
        Loading.hide()
        next()
      }
      // next()
    } else {
      store.dispatch('auth/getSessionStatus').then((sessionStatus) => {
        // se non ha la session torna al login
        if (!sessionStatus) {
          Loading.hide()
          next({
            path: i18n.t('routes.auth'),
            replace: false
          })
        } else {
          store.dispatch('auth/setLogin').then((setLogin) => {
            // Permessi disabled || hidden
            if (to.matched.find(record => !store.getters['permissions/checkPermission'](record.meta.permission))) {
              // se arriva da una blank page lo riporto alla home
              if (!helpers.methods.checkArray(from.matched)) {
                next({
                  path: i18n.t('routes.home'),
                  replace: true
                })
              } else {
                // altrimenti blocco la navigazione
                next(false)
              }
            } else {
              // Se ha il meta LoadForm
              if (to.matched.find(record => record.meta.loadForm)) {
                if (!store.getters['supplierForms/supplierFormsLoaded']) {
                  store.dispatch('supplierForms/loadSupplierForms')
                }
              }
              next()
            }
          }) // setLogin
        }
      })
    }
  })
  router.afterEach((to, from) => {
    if (from.path === i18n.t('routes.auth')) {
      Loading.hide()
    }
  })
}
