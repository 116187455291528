import axios from 'axios'
import { date } from 'quasar'

const getDefaultState = () => {
  return {
    messages: {
      data: []
    }
  }
}

// initial state
const state = getDefaultState()

const mutations = {
  resetStateMessages (state) {
    Object.assign(state, getDefaultState())
  },
  setData (state, value) {
    state.messages.data = value
  }
}

const actions = {
  resetStateMessages ({ commit }) {
    commit('resetStateMessages')
  },
  setData ({ commit, dispatch }, value) {
    commit('setData', value)
  },
  loadMessages ({ commit, rootState, rootGetters }) {
    let stateSettingUrl = rootGetters['settings/getSettingsUrls']
    let stateFilterMessages = rootState.filters.filters.messages
    let stateFilterMessagesPagination = rootState.filters.filters.messages.pagination
    let query = {
      query: {
        view_date: [],
        created_at: [],
        nome_ente: {
          $like: stateFilterMessages.nome_ente
        },
        subject: {
          $like: stateFilterMessages.subject
        }
      }
    }
    switch (stateFilterMessages.view_date) {
      case 1:
        query.query.view_date.push({ $not: null })
        break
      case 2:
        query.query.view_date.push({ $is: null })
        break
      case 0:
      default:
        break
    }
    if (stateFilterMessages.created_at_from) {
      query.query.created_at.push({ $gte: stateFilterMessages.created_at_from })
    }
    if (stateFilterMessages.created_at_to) {
      let dateTo = new Date(stateFilterMessages.created_at_to)
      dateTo.setHours(23, 59, 59)
      dateTo = date.formatDate(dateTo, 'YYYY-MM-DD HH:mm:ss')
      query.query.created_at.push({ $lte: dateTo })
    }
    let config = {
      params: {
        $limit: stateFilterMessagesPagination.rowsPerPage,
        $offset: stateFilterMessagesPagination.rowsOffset,
        $sort: [
          stateFilterMessagesPagination.sortBy + ' ' + (stateFilterMessagesPagination.descending ? 'DESC' : 'ASC')
        ],
        ...query
      }
    }
    let urlMessages = stateSettingUrl.networkpa_messages_all
    return axios.get(urlMessages, config)
      .then((response) => {
        commit('setData', { ...response.data.data })
        return response.data
      })
      .catch(() => {
        commit('setData', [])
        return false
      })
  }
}

const getters = {
  getAllMessages: state => {
    return Object.values(state.messages.data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
