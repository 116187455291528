import { mapActions, mapGetters } from 'vuex'

export default {
  methods: {
    ...mapActions('installations', [
      'setPrefer',
      'setDisableSelectRow',
      'setStatusSyncroAccount',
      'setStatusSyncroIscrizione',
      'loadInstallations'
    ])
  },
  computed: {
    ...mapGetters('installations', [
      'getStateInstallations',
      'getStateInstallationsAll',
      'getStateInstallationsToSyncro',
      'getStateInstallationsCanEnable',
      'getStateInstallationsInValidation',
      'getStateInstallationsValidated',
      'getStateInstallationsExpiring',
      'getStateInstallationsExpired',
      'getStateInstallationsWithSyncroSubscription',
      'getStateInstallationsPrefer',
      'getPlatformById'
    ])
  }
}
