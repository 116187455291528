import { mapActions, mapGetters } from 'vuex'

export default {
  methods: {
    ...mapActions('messages', [
      'loadMessages'
    ])
  },
  computed: {
    ...mapGetters('messages', [
      'getAllMessages'
    ])
  }
}
