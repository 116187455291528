const getDefaultState = () => {
  return {
    filters: {
      installations: {
        expansionHeight: 0,
        status: 0,
        name: '',
        type_ente: [],
        regione: []
      },
      tenders: {
        expansionHeight: 0,
        pagination: {
          sortBy: 'data_pubblicazione',
          descending: true,
          page: 1,
          rowsPerPage: 10,
          rowsOffset: 0,
          rowsNumber: 0
        },
        status_type: 0,
        cigOggetto: '',
        nome_ente: '',
        tipologia: [],
        cat_prevalente: [],
        tipologia_art190: [],
        stato: [],
        type_ente: [],
        regione: [],
        data_pubblicazione: '',
        data_pubblicazione_from: '',
        data_pubblicazione_to: '',
        data_scadenza: '',
        data_scadenza_from: '',
        data_scadenza_to: ''
      },
      tendersExternal: {
        pagination: {
          sortBy: 'data_pubblicazione',
          descending: true,
          page: 1,
          rowsPerPage: 10,
          rowsOffset: 0,
          rowsNumber: 0
        },
        cigOggetto: '',
        nome_ente: '',
        procedura: [],
        cat_prevalente: '',
        tipologia_art190: [],
        type_ente: [],
        regione: [],
        source: [],
        data_pubblicazione: '',
        data_pubblicazione_from: '',
        data_pubblicazione_to: '',
        data_scadenza: '',
        data_scadenza_from: '',
        data_scadenza_to: '',
        is_expiring: false
      },
      tenderResult: {
        pagination: {
          sortBy: 'data_pubblicazione',
          descending: true,
          page: 1,
          rowsPerPage: 10,
          rowsOffset: 0,
          rowsNumber: 0
        },
        cigOggetto: '',
        nome_ente: '',
        aggiudicatario: '',
        procedura: [],
        type_ente: [],
        cat_prevalente: '',
        tipologia_art190: [],
        data_aggiudicazione: '',
        data_aggiudicazione_from: '',
        data_aggiudicazione_to: '',
        importo: {
          min: 0,
          max: 6
        },
        source: []
      },
      messages: {
        expansionHeight: 0,
        pagination: {
          sortBy: 'created_at',
          descending: true,
          page: 1,
          rowsPerPage: 10,
          rowsOffset: 0,
          rowsNumber: 0
        },
        subject: '',
        nome_ente: '',
        created_at: '',
        created_at_from: '',
        created_at_to: '',
        view_date: 0
      },
      toSyncro: {
        expansionHeight: 0,
        statusSyncro: null,
        name: '',
        type_ente: [],
        regione: []
      },
      selected: {
        syncro: []
      }
    }
  }
}

// initial state
const state = getDefaultState()

const mutations = {
  resetStateFilters (state) {
    Object.assign(state, getDefaultState())
  },
  setInstallations (state, payload) {
    state.filters.installations[payload.key] = payload.value
  },
  resetFilterInstallations (state) {
    state.filters.installations.status = 0
    state.filters.installations.name = ''
    state.filters.installations.expiring = false
    state.filters.installations.expired = false
    state.filters.installations.type_ente = []
    state.filters.installations.regione = []
  },
  setTenders (state, payload) {
    state.filters.tenders[payload.key] = payload.value
  },
  setTendersExternal (state, payload) {
    state.filters.tendersExternal[payload.key] = payload.value
  },
  setTenderResult (state, payload) {
    state.filters.tenderResult[payload.key] = payload.value
  },
  setMessages (state, payload) {
    state.filters.messages[payload.key] = payload.value
  },
  setTendersPagination (state, payload) {
    state.filters.tenders.pagination[payload.key] = payload.value
  },
  setTendersExternalPagination (state, payload) {
    state.filters.tendersExternal.pagination[payload.key] = payload.value
  },
  setTenderResultPagination (state, payload) {
    state.filters.tenderResult.pagination[payload.key] = payload.value
  },
  setMessagesPagination (state, payload) {
    state.filters.messages.pagination[payload.key] = payload.value
  },
  resetFilterTenders (state) {
    state.filters.tenders.status_type = 0
    state.filters.tenders.cigOggetto = ''
    state.filters.tenders.nome_ente = ''
    state.filters.tenders.tipologia = []
    state.filters.tenders.tipologia_art190 = []
    state.filters.tenders.cat_prevalente = []
    state.filters.tenders.stato = []
    state.filters.tenders.type_ente = []
    state.filters.tenders.regione = []
    state.filters.tenders.data_pubblicazione = ''
    state.filters.tenders.data_pubblicazione_from = ''
    state.filters.tenders.data_pubblicazione_to = ''
    state.filters.tenders.data_scadenza = ''
    state.filters.tenders.data_scadenza_from = ''
    state.filters.tenders.data_scadenza_to = ''
  },
  resetFilterTendersExternal (state) {
    state.filters.tendersExternal.cigOggetto = ''
    state.filters.tendersExternal.nome_ente = ''
    state.filters.tendersExternal.procedura = []
    state.filters.tendersExternal.cat_prevalente = ''
    state.filters.tendersExternal.type_ente = []
    state.filters.tendersExternal.regione = []
    state.filters.tendersExternal.source = []
    state.filters.tendersExternal.data_pubblicazione = ''
    state.filters.tendersExternal.data_pubblicazione_from = ''
    state.filters.tendersExternal.data_pubblicazione_to = ''
    state.filters.tendersExternal.data_scadenza = ''
    state.filters.tendersExternal.data_scadenza_from = ''
    state.filters.tendersExternal.data_scadenza_to = ''
    state.filters.tendersExternal.is_expiring = false
  },
  resetFilterTenderResult (state) {
    state.filters.tenderResult.cigOggetto = ''
    state.filters.tenderResult.nome_ente = ''
    state.filters.tenderResult.aggiudicatario = ''
    state.filters.tenderResult.procedura = []
    state.filters.tenderResult.type_ente = []
    state.filters.tenderResult.cat_prevalente = ''
    state.filters.tenderResult.data_aggiudicazione = ''
    state.filters.tenderResult.data_aggiudicazione_from = ''
    state.filters.tenderResult.data_aggiudicazione_to = ''
    state.filters.tenderResult.importo = {
      min: 0,
      max: 6
    }
    state.filters.tenderResult.source = []
  },
  resetFilterMessages (state) {
    state.filters.messages.view_date = 0
    state.filters.messages.subject = ''
    state.filters.messages.nome_ente = ''
    state.filters.messages.created_at = ''
    state.filters.messages.created_at_from = ''
    state.filters.messages.created_at_to = ''
  },
  setToSyncro (state, payload) {
    state.filters.toSyncro[payload.key] = payload.value
  },
  resetFilterToSyncro (state) {
    state.filters.toSyncro.statusSyncro = null
    state.filters.toSyncro.name = ''
    state.filters.toSyncro.type_ente = []
    state.filters.toSyncro.regione = []
  },
  setSelectedSyncro (state, value) {
    state.filters.selected.syncro = value
  },
  resetSelectedSyncro (state) {
    state.filters.selected.syncro = []
  }
}

const actions = {
  resetStateFilters ({ commit }) {
    commit('resetStateFilters')
  },
  setInstallations ({ commit, dispatch }, payload) {
    commit('setInstallations', payload)
  },
  resetFilterInstallations ({ commit }) {
    commit('resetFilterInstallations')
  },
  setTenders ({ commit, dispatch }, payload) {
    commit('setTenders', payload)
    dispatch('tenders/loadTenders', {}, { root: true })
      .then((response) => {
        commit('setTendersPagination', {
          key: 'rowsNumber',
          value: response.meta.nb_filtered
        })
      })
  },
  setTendersExternal ({ commit, dispatch }, payload) {
    commit('setTendersExternal', payload)
    dispatch('tenders/loadTendersExternal', {}, { root: true })
      .then((response) => {
        commit('setTendersExternalPagination', {
          key: 'rowsNumber',
          value: response.meta.nb_filtered
        })
      })
  },
  setTenderResult ({ commit, dispatch }, payload) {
    commit('setTenderResult', payload)
    dispatch('tenders/loadTenderResult', {}, { root: true })
      .then((response) => {
        commit('setTenderResultPagination', {
          key: 'rowsNumber',
          value: response.meta.nb_filtered
        })
      })
  },
  setMessages ({ commit, dispatch }, payload) {
    commit('setMessages', payload)
  },
  setTendersPagination ({ commit, dispatch }, payload) {
    commit('setTendersPagination', payload)
  },
  setTendersExternalPagination ({ commit, dispatch }, payload) {
    commit('setTendersExternalPagination', payload)
  },
  setTenderResultPagination ({ commit, dispatch }, payload) {
    commit('setTenderResultPagination', payload)
  },
  setMessagesPagination ({ commit, dispatch }, payload) {
    commit('setMessagesPagination', payload)
  },
  resetFilterTenders ({ commit }) {
    commit('resetFilterTenders')
  },
  resetFilterTendersExternal ({ commit }) {
    commit('resetFilterTendersExternal')
  },
  resetFilterTenderResult ({ commit }) {
    commit('resetFilterTenderResult')
  },
  resetFilterMessages ({ commit }) {
    commit('resetFilterMessages')
  },
  setToSyncro ({ commit, dispatch }, payload) {
    commit('setToSyncro', payload)
    // commit('setSelectedSyncro', [])
  },
  resetFilterToSyncro ({ commit }) {
    commit('resetFilterToSyncro')
  },
  setSelectedSyncro ({ commit, dispatch, rootGetters }, value) {
    let getCountSelectableSyncroPlatform = rootGetters['user/getCountSelectableSyncroPlatform']
    let getInstallationSyncroPlatform = rootGetters['user/getInstallationSyncroPlatform']
    let IDs = [...value.map(obj => obj.id), ...getInstallationSyncroPlatform]
    let payload = IDs.filter((v, i, a) => a.indexOf(v) === i)
    if (getCountSelectableSyncroPlatform >= 0 && payload.length >= getCountSelectableSyncroPlatform) {
      dispatch('installations/setAllDisableSelectedMomentRow', payload, { root: true })
    } else {
      dispatch('installations/setAllEnableSelectedMomentRow', {}, { root: true })
    }
    commit('setSelectedSyncro', value)
  },
  resetSelectedSyncro ({ commit }) {
    commit('resetSelectedSyncro')
  },
  resetSelectSyncro ({ commit }, value) {
    let selected = Object.values(state.filters.selected.syncro).filter(select => (select.id !== value))
    commit('setSelectedSyncro', selected)
  },
  disableAllRowsExceptSelected () {
    console.log('disableAllRowsExceptSelected')
  }
}

const getters = {
  getFilters: state => {
    return state.filters
  },
  getFiltersInstallations: state => {
    return state.filters.installations
  },
  getFiltersTenders: state => {
    return state.filters.tenders
  },
  getFiltersTendersExternal: state => {
    return state.filters.tendersExternal
  },
  getFiltersTenderResult: state => {
    return state.filters.tenderResult
  },
  getFiltersMessages: state => {
    return state.filters.messages
  },
  getFiltersToSyncro: state => {
    return state.filters.toSyncro
  },
  getSelectedSyncro: state => {
    return state.filters.selected.syncro
  },
  isSelectedSyncro: state => {
    return !!(state.filters.selected.syncro).length
  },
  getCanSyncroAccount: state => {
    return Object.values(state.filters.selected.syncro).filter(installation => (installation.statusSyncroAccount === 1 || installation.statusSyncroAccount === 0) && installation.disableSelectRow === false && installation.selectTemporaryRow === false)
  },
  getSelectedSyncroAccount: state => {
    return Object.values(state.filters.selected.syncro).filter(installation => installation.statusSyncroAccount === 1 && installation.disableSelectRow === false && installation.selectTemporaryRow === false)
  },
  getCanSyncroSubscription: state => {
    return Object.values(state.filters.selected.syncro).filter(installation => installation.statusSyncroAccount === 1 && (installation.statusSyncroIscrizione === 0 || installation.statusSyncroIscrizione === 1) && installation.disableSelectRow === false && installation.selectTemporaryRow === false)
  },
  getSelectedSyncroSubscription: state => {
    return Object.values(state.filters.selected.syncro).filter(installation => installation.statusSyncroIscrizione === 3 && installation.disableSelectRow === false && installation.selectTemporaryRow === false)
  },
  getSelectedSyncroSubscriptionDisabled: state => {
    return Object.values(state.filters.selected.syncro).filter(installation => installation.statusSyncroIscrizione === 100 && installation.disableSelectRow === false && installation.selectTemporaryRow === false)
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
