import Vue from 'vue'
import Vuex from 'vuex'

import settings from 'store/store-settings'
import user from 'store/store-user'
import supplierForms from 'store/store-supplier-forms'
import installations from 'store/store-installations'
import filters from 'store/store-filters'
import syncro from 'store/store-syncro'
import documents from 'store/store-documents'
import tenders from 'store/store-tenders'
import messages from 'store/store-messages'
import permissions from 'store/store-permissions'
import auth from 'store/auth'
import dialogs from 'store/store-dialogs'
import session from 'store/auth-session'
import cookie from 'store/auth-cookie'
import notify from 'store/store-notify'

Vue.use(Vuex)

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation
 */

export default function (/* { ssrContext } */) {
  const Store = new Vuex.Store({
    modules: {
      settings,
      user,
      supplierForms,
      installations,
      filters,
      syncro,
      dialogs,
      documents,
      permissions,
      tenders,
      messages,
      auth,
      session,
      cookie,
      notify
    },

    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: process.env.DEV
  })

  return Store
}
