// let baseUrl = 'https://test1.networkpa.it'
// let ecommerceUrl = 'https://stage.networkpa.it'
import axios from 'axios'
let baseUrl = process.env.BASE_URL !== undefined ? process.env.BASE_URL : 'https://test1.networkpa.it'
let ecommerceUrl = process.env.ECOMERCE_URL !== undefined ? process.env.ECOMERCE_URL : 'https://stage.networkpa.it'

const getDefaultState = () => {
  return {
    settings: {
      drawer: false,
      miniState: false,
      chevron: 'fas fa-angle-double-left',
      secondFooter: {
        year: new Date().getFullYear(),
        piva: '03553050927',
        company: '<a class="digitalpaText" target="_blank" href="https://www.digitalpa.it"><b>DigitalPA S.r.l.</b></a>'
      },
      table: {
        iconTop: 'fas fa-table',
        iconPrefer: 'fas fa-star',
        iconPreferEmpty: 'far fa-star',
        iconUrl: 'far fa-arrow-alt-circle-right',
        iconViewAll: 'fas fa-chevron-right',
        iconDateExpired: 'fas fa-exclamation-triangle',
        iconPrivacy: 'fas fa-shield-alt',
        dayForExpiringAbilitation: 30,
        dayForExpiringTender: 1
      },
      url: {
        base_url: baseUrl,
        albo_step_riepilogo: '/profilo/modifica?step=step-riepilogo',
        networkpa_check: baseUrl + '/networkpa_check',
        networkpa_sync_credential: baseUrl + '/networkpa_sync',
        networkpa_desync_credential: baseUrl + '/networkpa_desync',
        networkpa_sync_subscription: baseUrl + '/networkpa_sync_subscription',
        networkpa_tender_all: baseUrl + '/npa/tender',
        networkpa_tender_external_all: baseUrl + '/npa/tender_external',
        networkpa_tender_result_all: baseUrl + '/npa/tender_result',
        networkpa_tender_public: baseUrl + '/npa/tender-public',
        networkpa_tender_invites: baseUrl + '/npa/tender-invites',
        networkpa_tender_subscriptions: baseUrl + '/npa/tender-subscriptions',
        networkpa_messages_all: baseUrl + '/npa/messages',
        networkpa_new_token: baseUrl + '/networkpa_new_token',
        networkpa_configurations: baseUrl + '/npa/get_configuration',
        networkpa_translations: baseUrl + '/npa/get_translations',
        networkpa_preference: baseUrl + '/npa/set-preference',
        paperboy_set_readed: baseUrl + '/pb-news/readed/',
        reset_discard: ecommerceUrl + '/profilo/password-persa/',
        create_account: ecommerceUrl + '/profilo/',
        edit_account: ecommerceUrl + '/profilo/modifica-profilo/',
        your_subscription: ecommerceUrl + '/profilo/abbonamenti/',
        subscription_plus: ecommerceUrl + '/prodotto/abbonamento-iscrizione-unica/'
      },
      logo: {
        globe: 'img:logo/networkpa-logo-globe.svg',
        npa: 'logo/logotipo-networkpa.svg',
        npaReverse: 'logo/logotipo-networkpa-reverse.svg'
      },
      background: 'background/worldwide-connection-blue.jpg',
      banners: [],
      faqs: [],
      regions: [],
      typeEnte: [],
      tenderStatus: [],
      tenderType: [],
      tenderExternalSource: [],
      tenderExternalProcedura: [],
      tenderResultSource: [],
      tenderResultProcedura: [],
      tenderTypeAnac: [],
      termsAndConditions: [],
      languages: [],
      videoguide: []
    }
  }
}

// initial state
const state = getDefaultState()

const mutations = {
  resetStateSettings (state) {
    Object.assign(state, getDefaultState())
  },
  setSettings (state, settings) {
    Object.assign(state.settings, settings)
  },
  setDrawer (state, value) {
    state.settings.drawer = value
  },
  setMiniState (state, value) {
    state.settings.miniState = value
  },
  setChevron (state, value) {
    state.settings.chevron = value
  },
  setBanners (state, value) {
    state.settings.banners = value
  },
  setFaqs (state, value) {
    state.settings.faqs = value
  },
  setRegions (state, value) {
    state.settings.regions = value
  },
  setTypeEnte (state, value) {
    state.settings.typeEnte = value
  },
  setLanguages (state, value) {
    state.settings.languages = value
  },
  setVideoguide (state, value) {
    state.settings.videoguide = value
  },
  setTenderStatus (state, value) {
    state.settings.tenderStatus = value
  },
  setTenderType (state, value) {
    state.settings.tenderType = value
  },
  setTenderExternalSource (state, value) {
    state.settings.tenderExternalSource = value
  },
  setTenderExternalProcedura (state, value) {
    state.settings.tenderExternalProcedura = value
  },
  setTenderResultSource (state, value) {
    state.settings.tenderResultSource = value
  },
  setTenderResultProcedura (state, value) {
    state.settings.tenderResultProcedura = value
  },
  setTenderTypeAnac (state, value) {
    state.settings.tenderTypeAnac = value
  },
  setTenderTipoFornitura (state, value) {
    state.settings.tenderTipoFornitura = value
  },
  setCatPrevalenti (state, value) {
    state.settings.catPrevalenti = value
  },
  setDayForExpiringTender (state, value) {
    state.settings.table.dayForExpiringTender = value
  },
  setDayForExpiringAbilitation (state, value) {
    state.settings.table.dayForExpiringAbilitation = value
  },
  setTermsAndConditions (state, value) {
    state.settings.termsAndConditions = value
  }
}

const actions = {
  resetStateSettings ({ commit }) {
    commit('resetStateSettings')
  },
  setDrawer ({ commit, dispatch }, value) {
    commit('setDrawer', value)
  },
  setMiniState ({ commit, dispatch }, value) {
    if (value === true) {
      dispatch('setChevron', 'fas fa-angle-double-right')
    } else {
      dispatch('setChevron', 'fas fa-angle-double-left')
    }
    commit('setMiniState', value)
  },
  setChevron ({ commit, dispatch }, value) {
    commit('setChevron', value)
  },
  loadConfigurations ({ commit, state }) {
    return axios.get(state.settings.url.networkpa_configurations)
      .then((response) => {
        commit('setDayForExpiringTender', response.data.data.dayForExpiringTender)
        commit('setDayForExpiringAbilitation', response.data.data.dayForExpiringAbilitation)
        commit('setBanners', response.data.data.banners)
        commit('setFaqs', response.data.data.faq)
        commit('setTenderStatus', response.data.data.tenderStatus)
        commit('setTenderType', response.data.data.tenderType)
        commit('setTenderExternalSource', response.data.data.tenderExternalSource)
        commit('setTenderExternalProcedura', response.data.data.tenderExternalProcedura)
        commit('setTenderResultSource', response.data.data.tenderResultSource)
        commit('setTenderResultProcedura', response.data.data.tenderResultProcedura)
        commit('setTenderTypeAnac', response.data.data.tenderTypeAnac)
        commit('setTenderTipoFornitura', response.data.data.tenderTipoFornitura)
        commit('setCatPrevalenti', response.data.data.catPrevalenti)
        commit('setTypeEnte', response.data.data.typeEnte)
        commit('setRegions', response.data.data.region)
        commit('setTermsAndConditions', response.data.data.terms)
        commit('setLanguages', response.data.data.languages)
        commit('setVideoguide', response.data.data.videoguide)
      })
      .catch(() => {
      })
  }
}

const getters = {
  getSettings: state => {
    return state.settings
  },
  getDrawer: state => {
    return state.settings.drawer
  },
  getMiniState: state => {
    return state.settings.miniState
  },
  getChevron: state => {
    return state.settings.chevron
  },
  getSecondFooter: state => {
    return state.settings.secondFooter
  },
  getBackground: state => {
    return state.settings.background
  },
  getSettingsTable: state => {
    return state.settings.table
  },
  getSettingsUrls: state => {
    return state.settings.url
  },
  getLogoImage: state => {
    return state.settings.logo
  },
  getBanners: state => {
    return state.settings.banners
  },
  getFaqs: state => {
    return state.settings.faqs
  },
  getRegions: state => {
    return state.settings.regions
  },
  getTypeEnte: state => {
    return state.settings.typeEnte
  },
  getLanguages: state => {
    return state.settings.languages
  },
  getVideoguide: state => {
    return state.settings.videoguide
  },
  getTenderStatus: state => {
    return state.settings.tenderStatus
  },
  getTenderType: state => {
    return state.settings.tenderType
  },
  getTenderExternalSource: state => {
    return state.settings.tenderExternalSource
  },
  getTenderExternalProcedura: state => {
    return state.settings.tenderExternalProcedura
  },
  getTenderResultSource: state => {
    return state.settings.tenderResultSource
  },
  getTenderResultProcedura: state => {
    return state.settings.tenderResultProcedura
  },
  getTenderTypeAnac: state => {
    return state.settings.tenderTypeAnac
  },
  getTenderTipoFornitura: state => {
    return state.settings.tenderTipoFornitura
  },
  getCatPrevalenti: state => {
    return state.settings.catPrevalenti
  },
  getDayForExpiringTender: state => {
    return state.settings.table.dayForExpiringTender
  },
  getDayForExpiringAbilitation: state => {
    return state.settings.table.dayForExpiringAbilitation
  },
  getTermsAndConditions: state => {
    return state.settings.termsAndConditions
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
