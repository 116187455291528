import { mapActions, mapGetters } from 'vuex'

export default {
  methods: {
    ...mapActions('tenders', [
      'setPrefer',
      'loadTenders',
      'loadTendersExternal',
      'loadTenderResult',
      'loadTendersCruscotto'
    ])
  },
  computed: {
    ...mapGetters('tenders', [
      'getAllTenders',
      'getAllTendersExternal',
      'getAllTenderResult',
      'getTendersLoading',
      'getTendersExternalLoading',
      'getTenderResultLoading',
      'getTenderCruscottoPublic',
      'getTenderCruscottoPublicMeta',
      'getTenderCruscottoInvites',
      'getTenderCruscottoInvitesMeta',
      'getTenderCruscottoSubscriptions',
      'getTenderCruscottoSubscriptionsMeta'
    ])
  }
}
