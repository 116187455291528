import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      dpadisable: false,
      dpaenable: false,
      dpahidden: false,
      dpavisible: false
    }
  },
  methods: {
    ...mapGetters('permissions', [
      'checkPermission'
    ]),
    dpaHidden () {
      this.dpahidden = true
    },
    dpaVisible () {
      this.dpavisible = true
    },
    dpaDisable () {
      this.dpadisable = true
    },
    dpaEnable () {
      this.dpaenable = true
    }
  },
  computed: {
    ...mapGetters('permissions', [
      'getPermission'
    ]),
    dpa_disable: function () {
      return this.dpadisable
    },
    dpa_enable: function () {
      return this.dpaenable
    },
    dpa_hidden: function () {
      return this.dpahidden
    },
    dpa_visible: function () {
      return this.dpavisible
    }
  }
}
