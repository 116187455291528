import { i18n } from 'boot/i18n'

const routes = [
  {
    path: '/',
    component: () => import('layouts/Layout.vue'),
    meta: {
      auth: false
    },
    children: [
      {
        path: i18n.t('routes.auth'),
        component: () => import('pages/PageAuth.vue')
      }
    ]
  },
  {
    path: '/supplier',
    component: () => import('layouts/Layout.vue'),
    meta: {
      auth: true
    },
    children: [
      {
        path: i18n.t('routes.home'),
        component: () => import('pages/PageHome.vue'),
        meta: {
          permission: 'npa.routes_home'
        }
      },
      {
        path: i18n.t('routes.cruscotto'),
        component: () => import('pages/PageCruscotto.vue'),
        meta: {
          permission: 'npa.routes_cruscotto'
        }
      },
      {
        path: i18n.t('routes.iscrizione'),
        component: () => import('pages/PageIscrizione.vue'),
        meta: {
          loadForm: true,
          permission: 'npa.routes_iscrizione'
        }
      },
      {
        path: i18n.t('routes.sincronizzazione'),
        component: () => import('pages/PageSyncro.vue'),
        meta: {
          permission: 'npa.routes_sincronizzazione'
        }
      },
      {
        path: i18n.t('routes.area_comunicazioni'),
        component: () => import('pages/PageAreaComunicazioni.vue'),
        meta: {
          permission: 'npa.routes_area_comunicazioni'
        }
      },
      {
        path: i18n.t('routes.albi_fornitori'),
        component: () => import('pages/PageInstallations.vue'),
        meta: {
          permission: 'npa.routes_albi_fornitori'
        }
      },
      {
        path: i18n.t('routes.bandi_gara'),
        component: () => import('pages/PageTenders.vue'),
        meta: {
          permission: 'npa.routes_bandi_gara'
        }
      },
      {
        path: i18n.t('routes.info_bandi'),
        component: () => import('pages/PageInfoBandi.vue'),
        meta: {
          permission: 'npa.routes_info_bandi'
        }
      },
      {
        path: i18n.t('routes.tender_result'),
        component: () => import('pages/PageTenderResult.vue'),
        meta: {
          permission: 'npa.routes_tender_result'
        }
      },
      {
        path: i18n.t('routes.internal_faq'),
        component: () => import('pages/PageFaq.vue'),
        meta: {
          permission: 'npa.routes_internal_faq'
        }
      },
      {
        path: i18n.t('routes.supporto_tecnico'),
        component: () => import('pages/PageSupportoTecnico.vue'),
        meta: {
          permission: 'npa.routes_supporto_tecnico'
        }
      },
      {
        path: i18n.t('routes.regolamento'),
        component: () => import('pages/PageRegolamento.vue'),
        meta: {
          permission: 'npa.routes_regolamento'
        }
      },
      {
        path: i18n.t('routes.area_notifiche'),
        component: () => import('pages/PageNotifiche.vue'),
        meta: {
          permission: 'npa.routes_area_notifiche'
        }
      },
      {
        path: i18n.t('routes.videoguide'),
        component: () => import('pages/PageVideoguide.vue'),
        meta: {
          permission: 'npa.routes_videoguide'
        }
      },
      {
        path: i18n.t('routes.prova'),
        component: () => import('pages/PageProva.vue'),
        meta: {
          permission: 'npa.routes_prova'
        }
      }
    ]
  }

]

// Always leave this as last one
routes.push({
  path: '*',
  component: () => import('pages/Error404.vue')
})

export default routes
