import axios from 'axios'
import helpers from 'mixins/mixin-helpers'
import { date } from 'quasar'

function transformOrLikeCatPrevalente (array) {
  let catPrevalente = []
  array.map(value => catPrevalente.push({
      $like: value
    }
  ))
  return catPrevalente
}

const valueImportoArray = {
  0: 0,
  1: 10000,
  2: 40000,
  3: 150000,
  4: 500000,
  5: 1000000,
  6: 999999999
}

const getDefaultState = () => {
  return {
    tenders: {
      data: [],
      loading: false
    },
    tendersExternal: {
      data: [],
      loading: false
    },
    tenderResult: {
      data: [],
      loading: false
    },
    tendersCruscottoPublic: {
      data: [],
      meta: []
    },
    tendersCruscottoInvites: {
      data: [],
      meta: []
    },
    tendersCruscottoSubscriptions: {
      data: [],
      meta: []
    }
  }
}

// initial state
const state = getDefaultState()

const mutations = {
  resetStateTenders (state) {
    Object.assign(state, getDefaultState())
  },
  setData (state, value) {
    state.tenders.data = value
  },
  setDataExternal (state, value) {
    state.tendersExternal.data = value
  },
  setDataResult (state, value) {
    state.tenderResult.data = value
  },
  setTendersLoading (state, value) {
    state.tenders.loading = value
  },
  setTendersExternalLoading (state, value) {
    state.tendersExternal.loading = value
  },
  setTenderResultLoading (state, value) {
    state.tenderResult.loading = value
  },
  setDataCruscottoPublic (state, value) {
    state.tendersCruscottoPublic.data = value
  },
  setMetaCruscottoPublic (state, value) {
    state.tendersCruscottoPublic.meta = value
  },
  setDataCruscottoInvites (state, value) {
    state.tendersCruscottoInvites.data = value
  },
  setMetaCruscottoInvites (state, value) {
    state.tendersCruscottoInvites.meta = value
  },
  setDataCruscottoSubscription (state, value) {
    state.tendersCruscottoSubscriptions.data = value
  },
  setMetaCruscottoSubscription (state, value) {
    state.tendersCruscottoSubscriptions.meta = value
  },
  setPreferTender (state, index) {
    if (!helpers.methods.isUndefined(state.tenders.data[index]) && !helpers.methods.checkArray(state.tenders.data[index])) {
      state.tenders.data[index].prefer = !state.tenders.data[index].prefer
    }
    if (!helpers.methods.isUndefined(state.tendersCruscottoPublic.data[index]) && !helpers.methods.checkArray(state.tendersCruscottoPublic.data[index])) {
      state.tendersCruscottoPublic.data[index].prefer = !state.tendersCruscottoPublic.data[index].prefer
    }
    if (!helpers.methods.isUndefined(state.tendersCruscottoSubscriptions.data[index]) && !helpers.methods.checkArray(state.tendersCruscottoSubscriptions.data[index])) {
      state.tendersCruscottoSubscriptions.data[index].prefer = !state.tendersCruscottoSubscriptions.data[index].prefer
    }
    if (!helpers.methods.isUndefined(state.tendersCruscottoInvites.data[index]) && !helpers.methods.checkArray(state.tendersCruscottoInvites.data[index])) {
      state.tendersCruscottoInvites.data[index].prefer = !state.tendersCruscottoInvites.data[index].prefer
    }
  },
  setPreferTenderExternal (state, index) {
    if (!helpers.methods.isUndefined(state.tendersExternal.data[index]) && !helpers.methods.checkArray(state.tendersExternal.data[index])) {
      state.tendersExternal.data[index].prefer = !state.tendersExternal.data[index].prefer
    }
  },
  setPreferTenderResult (state, index) {
    if (!helpers.methods.isUndefined(state.tenderResult.data[index]) && !helpers.methods.checkArray(state.tenderResult.data[index])) {
      state.tenderResult.data[index].prefer = !state.tenderResult.data[index].prefer
    }
  }
}

const actions = {
  resetStateTenders ({ commit }) {
    commit('resetStateTenders')
  },
  setData ({ commit, dispatch }, value) {
    commit('setData', value)
  },
  setTendersLoading ({ commit, dispatch }, value) {
    commit('setTendersLoading', value)
  },
  setTendersExternalLoading ({ commit, dispatch }, value) {
    commit('setTendersExternalLoading', value)
  },
  setTenderResultLoading ({ commit, dispatch }, value) {
    commit('setTenderResultLoading', value)
  },
  setPreferTender ({ commit, dispatch }, payload) {
    commit('setPreferTender', payload)
  },
  setPreferTenderExternal ({ commit, dispatch }, payload) {
    commit('setPreferTenderExternal', payload)
  },
  setPreferTenderResult ({ commit, dispatch }, payload) {
    commit('setPreferTenderResult', payload)
  },
  loadTendersCruscotto ({ commit, rootGetters }) {
    let config = {
      params: {
        $limit: 10,
        $offset: 0,
        $sort: [
          'prefer DESC',
          'data_pubblicazione' + ' ' + 'DESC'
        ]
      }
    }
    axios.get(rootGetters['settings/getSettingsUrls'].networkpa_tender_public, config)
      .then((response) => {
        commit('setDataCruscottoPublic', { ...response.data.data })
        commit('setMetaCruscottoPublic', { ...response.data.meta })
      })
      .catch(() => {
        commit('setDataCruscottoPublic', [])
        commit('setMetaCruscottoPublic', [])
        return false
      })

    axios.get(rootGetters['settings/getSettingsUrls'].networkpa_tender_invites, config)
      .then((response) => {
        commit('setDataCruscottoInvites', { ...response.data.data })
        commit('setMetaCruscottoInvites', { ...response.data.meta })
      })
      .catch(() => {
        commit('setDataCruscottoInvites', [])
        commit('setMetaCruscottoInvites', [])
        return false
      })

    axios.get(rootGetters['settings/getSettingsUrls'].networkpa_tender_subscriptions, config)
      .then((response) => {
        commit('setDataCruscottoSubscription', { ...response.data.data })
        commit('setMetaCruscottoSubscription', { ...response.data.meta })
      })
      .catch(() => {
        commit('setDataCruscottoSubscription', [])
        commit('setMetaCruscottoSubscription', [])
        return false
      })
  },
  loadTenders ({ commit, rootState, rootGetters, getters }) {
    commit('setTendersLoading', true)
    let stateSettingUrl = rootGetters['settings/getSettingsUrls']
    let stateFilterTender = rootState.filters.filters.tenders
    let stateFilterTenderPagination = rootState.filters.filters.tenders.pagination
    let query = {
      query: {
        $or: [
          {
            oggetto: {
              $like: stateFilterTender.cigOggetto
            }
          },
          {
            cig: {
              $like: stateFilterTender.cigOggetto
            }
          }
        ],
        data_pubblicazione: [],
        nome_ente: {
          $like: stateFilterTender.nome_ente
        },
        stato: {
          $or: stateFilterTender.stato
        },
        tipologia_art190: {
          $or: stateFilterTender.tipologia_art190
        },
        cat_prevalente: {
          $or: transformOrLikeCatPrevalente(stateFilterTender.cat_prevalente)
        },
        type_ente: {
          $or: stateFilterTender.type_ente
        },
        regione: {
          $or: stateFilterTender.regione
        }
      }
    }
    if (stateFilterTender.data_pubblicazione_from) {
      query.query.data_pubblicazione.push({ $gte: stateFilterTender.data_pubblicazione_from })
    }
    if (stateFilterTender.data_pubblicazione_to) {
      let dateTo = new Date(stateFilterTender.data_pubblicazione_to)
      dateTo.setHours(23, 59, 59)
      dateTo = date.formatDate(dateTo, 'YYYY-MM-DD HH:mm:ss')
      query.query.data_pubblicazione.push({ $lte: dateTo })
    }

    switch (stateFilterTender.status_type) {
      case 0:
        break
      case 1:
        query.query.$having = [
          { 'is_subscribed': 1 }
        ]
        break
      case 2:
        query.query.$having = [
          { 'is_invited': 1 }
        ]
        break
      default:
        break
    }

    let config = {
      params: {
        $limit: stateFilterTenderPagination.rowsPerPage,
        $offset: stateFilterTenderPagination.rowsOffset,
        $sort: [
          'prefer DESC',
          stateFilterTenderPagination.sortBy + ' ' + (stateFilterTenderPagination.descending ? 'DESC' : 'ASC')
        ],
        ...query
      }
    }
    let urlTender = stateSettingUrl.networkpa_tender_all
    return axios.get(urlTender, config)
      .then((response) => {
        commit('setData', { ...response.data.data })
        commit('setTendersLoading', false)
        return response.data
      })
      .catch(() => {
        commit('setData', [])
        commit('setTendersLoading', false)
        return false
      })
  },
  loadTendersExternal ({ commit, rootState, rootGetters, getters }) {
    commit('setTendersExternalLoading', true)
    let stateSettingUrl = rootGetters['settings/getSettingsUrls']
    let stateFilterTenderExternal = rootState.filters.filters.tendersExternal
    let stateFilterTenderExternalPagination = rootState.filters.filters.tendersExternal.pagination
    let query = {
      query: {
        $or: [
          {
            oggetto: {
              $like: stateFilterTenderExternal.cigOggetto
            }
          },
          {
            cig: {
              $like: stateFilterTenderExternal.cigOggetto
            }
          }
        ],
        data_pubblicazione: [],
        nome_ente: {
          $like: stateFilterTenderExternal.nome_ente
        },
        procedura: {
          $or: stateFilterTenderExternal.procedura
        },
        cat_prevalente: {
          $like: stateFilterTenderExternal.cat_prevalente
        },
        type_ente: {
          $or: stateFilterTenderExternal.type_ente
        },
        regione: {
          $or: stateFilterTenderExternal.regione
        },
        source: {
          $or: stateFilterTenderExternal.source
        }
      }
    }
    if (stateFilterTenderExternal.data_pubblicazione_from) {
      query.query.data_pubblicazione.push({ $gte: stateFilterTenderExternal.data_pubblicazione_from })
    }
    if (stateFilterTenderExternal.data_pubblicazione_to) {
      let dateTo = new Date(stateFilterTenderExternal.data_pubblicazione_to)
      dateTo.setHours(23, 59, 59)
      dateTo = date.formatDate(dateTo, 'YYYY-MM-DD HH:mm:ss')
      query.query.data_pubblicazione.push({ $lte: dateTo })
    }

    if (stateFilterTenderExternal.is_expiring) {
      // console.log('is_expiring')
      // if (typeof query.query['$havingor'] === 'undefined') {
      //   query.query = {
      //     ...query.query,
      //     ...{ '$havingor': [] }
      //   }
      // }
      // query.query['$havingor'].push({ 'is_expiring': '1' }, { 'is_expiring': '0' })
    } else {
      // console.log('is_not_expiring')
      if (typeof query.query['$havingor'] === 'undefined') {
        query.query = {
          ...query.query,
          ...{ '$havingor': [] }
        }
      }
      query.query['$havingor'].push({ 'is_expiring': '0' })
    }

    let config = {
      params: {
        $limit: stateFilterTenderExternalPagination.rowsPerPage,
        $offset: stateFilterTenderExternalPagination.rowsOffset,
        $sort: [
          'prefer DESC',
          stateFilterTenderExternalPagination.sortBy + ' ' + (stateFilterTenderExternalPagination.descending ? 'DESC' : 'ASC')
        ],
        ...query
      }
    }
    let urlTenderExternal = stateSettingUrl.networkpa_tender_external_all
    return axios.get(urlTenderExternal, config)
      .then((response) => {
        commit('setDataExternal', { ...response.data.data })
        commit('setTendersExternalLoading', false)
        return response.data
      })
      .catch(() => {
        commit('setDataExternal', [])
        commit('setTendersExternalLoading', false)
        return false
      })
  },
  loadTenderResult ({ commit, rootState, rootGetters, getters }) {
    commit('setTenderResultLoading', true)
    let stateSettingUrl = rootGetters['settings/getSettingsUrls']
    let stateFilterTenderResult = rootState.filters.filters.tenderResult
    let stateFilterTenderResultPagination = rootState.filters.filters.tenderResult.pagination
    let query = {
      query: {
        $or: [
          {
            oggetto: {
              $like: stateFilterTenderResult.cigOggetto
            }
          },
          {
            cig: {
              $like: stateFilterTenderResult.cigOggetto
            }
          }
        ],
        nome_ente: {
          $like: stateFilterTenderResult.nome_ente
        },
        aggiudicatario: {
          $like: stateFilterTenderResult.aggiudicatario
        },
        procedura: {
          $or: stateFilterTenderResult.procedura
        },
        type_ente: {
          $or: stateFilterTenderResult.type_ente
        },
        cat_prevalente: {
          $like: stateFilterTenderResult.cat_prevalente
        },
        source: {
          $or: stateFilterTenderResult.source
        },
        data_aggiudicazione: [],
        importo: []
      }
    }
    if (stateFilterTenderResult.importo) {
      query.query.importo.push({ $gte: valueImportoArray[stateFilterTenderResult.importo.min] })
      query.query.importo.push({ $lte: valueImportoArray[stateFilterTenderResult.importo.max] })
      // let importoSplitted = element.split('_')
    }
    if (stateFilterTenderResult.data_aggiudicazione_from) {
      query.query.data_aggiudicazione.push({ $gte: stateFilterTenderResult.data_aggiudicazione_from })
    }
    if (stateFilterTenderResult.data_aggiudicazione_to) {
      let dateTo = new Date(stateFilterTenderResult.data_aggiudicazione_to)
      dateTo.setHours(23, 59, 59)
      dateTo = date.formatDate(dateTo, 'YYYY-MM-DD HH:mm:ss')
      query.query.data_aggiudicazione.push({ $lte: dateTo })
    }

    let config = {
      params: {
        $limit: stateFilterTenderResultPagination.rowsPerPage,
        $offset: stateFilterTenderResultPagination.rowsOffset,
        $sort: [
          'prefer DESC',
          stateFilterTenderResultPagination.sortBy + ' ' + (stateFilterTenderResultPagination.descending ? 'DESC' : 'ASC')
        ],
        ...query
      }
    }
    let urlTenderResult = stateSettingUrl.networkpa_tender_result_all
    return axios.get(urlTenderResult, config)
      .then((response) => {
        commit('setDataResult', { ...response.data.data })
        commit('setTenderResultLoading', false)
        return response.data
      })
      .catch(() => {
        commit('setDataResult', [])
        commit('setTenderResultLoading', false)
        return false
      })
  }
}

const getters = {

  getAllTenders: state => {
    return Object.values(state.tenders.data)
  },
  getAllTendersExternal: state => {
    return Object.values(state.tendersExternal.data)
  },
  getAllTenderResult: state => {
    return Object.values(state.tenderResult.data)
  },
  getTendersLoading: state => {
    return state.tenders.loading
  },
  getTendersExternalLoading: state => {
    return state.tendersExternal.loading
  },
  getTenderResultLoading: state => {
    return state.tenderResult.loading
  },
  getTenderCruscottoPublic: state => {
    return Object.values(state.tendersCruscottoPublic.data).sort((a, b) => Number(b.prefer) - Number(a.prefer))
  },
  getTenderCruscottoPublicMeta: state => {
    return state.tendersCruscottoPublic.meta
  },
  getTenderCruscottoInvites: state => {
    return Object.values(state.tendersCruscottoInvites.data).sort((a, b) => Number(b.prefer) - Number(a.prefer))
  },
  getTenderCruscottoInvitesMeta: state => {
    return state.tendersCruscottoInvites.meta
  },
  getTenderCruscottoSubscriptions: state => {
    return Object.values(state.tendersCruscottoSubscriptions.data).sort((a, b) => Number(b.prefer) - Number(a.prefer))
  },
  getTenderCruscottoSubscriptionsMeta: state => {
    return state.tendersCruscottoSubscriptions.meta
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
