
const getDefaultState = () => {
  return {
    permissions: {
      data: []
    }
  }
}

// initial state
const state = getDefaultState()

const mutations = {
  resetStatePermissions (state) {
    Object.assign(state, getDefaultState())
  },
  setData (state, value) {
    state.permissions.data = value
  }
}

const actions = {
  resetStatePermissions ({ commit }) {
    commit('resetStatePermissions')
  },
  setData ({ commit, dispatch }, value) {
    commit('setData', value)
  }
}

const getters = {
  getAllPermissions: state => {
    return Object.values(state.permissions.data)
  },
  getPermission: (state) => (key) => {
    return (Object.values(state.permissions.data)).includes(key)
  },
  checkPermission: (state, getters) => (key) => {
    return !(getters.getPermission(key + '_disabled') || getters.getPermission(key + '_hidden'))
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
