import axios from 'axios'
import { Notify } from 'quasar'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { i18n } from 'boot/i18n'

const getDefaultState = () => {
  return {
    notify: {
      list: []
    },
    notify_events: []
  }
}

// initial state
const state = getDefaultState()

const mutations = {
  resetStateNotify (state) {
    Object.assign(state, getDefaultState())
  },
  setNotify (state, value) {
    state.notify = value
  },
  setNotifyList (state, value) {
    state.notify.list = value
  },
  addNotifyRow (state, value) {
    state.notify.list.unshift(value)
  },
  setNotifyEvents (state, value) {
    state.notify_events = value
  },
  addNotifyEvents (state, value) {
    state.notify_events.push(value)
  }
}
const actions = {
  resetStateNotify ({ commit }) {
    commit('resetStateNotify')
  },
  loadNotify ({ commit, rootGetters, dispatch }) {
    return axios.get(rootGetters['settings/getSettingsUrls'].base_url + '/npa/notify-all')
      .then((response) => {
        commit('setNotifyList', response.data.data)
      })
      .catch((error) => {
        commit('setNotifyList', [])
        return JSON.parse(JSON.stringify(error))
      })
  },
  setNotifyRead ({ commit, dispatch, rootGetters, state }, data) {
    axios.post(rootGetters['settings/getSettingsUrls'].base_url + '/npa/notification-recived', {
      token_id: data.token
    },
    { headers: { 'Content-type': 'application/x-www-form-urlencoded' } }
    )
      .then((response) => {
        if (response.data === 'success') {
          dispatch('loadNotify')
        }
      })
      .catch((error) => {
        return JSON.parse(JSON.stringify(error))
      })
  },
  setNotifyReadAll ({ commit, dispatch, rootGetters, state }, data) {
    axios.post(rootGetters['settings/getSettingsUrls'].base_url + '/npa/notification-recived-all', {},
      { headers: { 'Content-type': 'application/x-www-form-urlencoded' } }
    )
      .then((response) => {
        if (response.data === 'success') {
          dispatch('loadNotify')
        }
      })
      .catch((error) => {
        return JSON.parse(JSON.stringify(error))
      })
  },
  loadNotifyRuntime ({ commit, rootGetters, dispatch }) {
    return axios.get(rootGetters['settings/getSettingsUrls'].base_url + '/npa/notification-system-init')
      .then((response) => {
        const data = response.data
        const topics = data.topics
        const hubs = data.hubs
        const token = data.jwt

        hubs.forEach(function (hub, index, arr) {
          let url = new URL(hub)
          for (let topic in topics) {
            const topicArr = topics[topic]
            topicArr.forEach(function (topicUrl, index, arr) {
              url.searchParams.append('topic', topicUrl)
            })
          }
          dispatch('addNotify', { url: url, token: token })
        })
      })
      .catch((error) => {
        return JSON.parse(JSON.stringify(error))
      })
  },
  addNotify ({ commit, dispatch, rootState, state }, value) {
    const es = new EventSourcePolyfill(value.url, {
      headers: {
        'Authorization': 'Bearer ' + value.token
      }
    })
    es.onmessage = event => {
      let data = JSON.parse(event.data)
      let url = '#'
      let target = '_self'
      if (data.linkType === 'quasar_route') {
        target = '_self'
        url = '#' + i18n.t('routes.' + data.linkTo)
      } else if (data.linkType === 'af_route') {
        url = data.linkTo
        target = '_blank'
      }
      let title = (data.type !== undefined) ? i18n.t('lbl_notify_' + data.type) : ''
      let link = (data.type !== undefined) ? '<br>&nbsp;<br><a href="' + url + '" target="' + target + '" class="btn_notify">' + i18n.t('lbl_btn_' + data.type) + ' ></a>' : ''
      Notify.create({
        // type: 'info',
        group: data.type,
        message: title,
        caption: i18n.t(data['msg']['label'], data['msg']['params']) + link,
        position: 'top-right',
        color: 'white',
        textColor: 'primary',
        border: 'teal',
        multiLine: true,
        html: true,
        progress: true,
        classes: 'border-notify width-notify'
        // timeout: 50000,
        // closeBtn: i18n.t('lbl_close')
      })
      let DateNow = new Date().toLocaleString()
      let payloadRow = {
        type: data.type,
        sender: data.platform,
        targets: [],
        payload: data,
        user_id: '',
        recived: false,
        recived_date: null,
        created_at: DateNow
      }
      commit('addNotifyRow', payloadRow)
    }
    commit('addNotifyEvents', es)
  },
  closeConnection ({ state, commit }) {
    state.notify_events.forEach(function (item) {
      item.close()
    })
    commit('setNotifyEvents', [])
  }

}

const getters = {
  getNotifyPreviewList: state => {
    return Object.values(state.notify.list).filter(notify => (notify.recived === false)).slice(0, 5)
  },
  getNotifyPreviewCount: state => {
    return Object.values(state.notify.list).filter(notify => (notify.recived === false)).length
  },
  getNotify: state => {
    return state.notify
  },
  getNotifyList: state => {
    return state.notify.list
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
