import { mapActions, mapGetters } from 'vuex'

export default {
  methods: {
    ...mapActions('filters', [
      'setInstallations',
      'resetFilterInstallations',
      'resetFilterTenders',
      'resetFilterTendersExternal',
      'resetFilterTenderResult',
      'resetFilterMessages',
      'resetFilterToSyncro',
      'resetSelectedSyncro',
      'resetSelectSyncro',
      'setTenders',
      'setTendersExternal',
      'setTenderResult',
      'setMessages',
      'setTendersPagination',
      'setTendersExternalPagination',
      'setTenderResultPagination',
      'setMessagesPagination',
      'setToSyncro',
      'setSelectedSyncro'
    ])
  },
  computed: {
    ...mapGetters('filters', [
      'getFilters',
      'getFiltersInstallations',
      'getFiltersTenders',
      'getFiltersTendersExternal',
      'getFiltersTenderResult',
      'getFiltersMessages',
      'getFiltersToSyncro',
      'getSelectedSyncro',
      'isSelectedSyncro',
      'getCanSyncroAccount',
      'getSelectedSyncroAccount',
      'getCanSyncroSubscription',
      'getSelectedSyncroSubscription',
      'getSelectedSyncroSubscriptionDisabled'
    ]),
    filters: {
      get () {
        return this.getFilters
      }
    },
    filtersInstallations: {
      get () {
        return this.getFiltersInstallations
      },
      set (value) {
        this.setInstallations(value)
      }
    },
    filtersTenders: {
      get () {
        return this.getFiltersTenders
      },
      set (value) {
        this.setTenders(value)
      }
    },
    filtersTendersExternal: {
      get () {
        return this.getFiltersTendersExternal
      },
      set (value) {
        this.setTendersExternal(value)
      }
    },
    filtersTenderResult: {
      get () {
        return this.getFiltersTenderResult
      },
      set (value) {
        this.setTenderResult(value)
      }
    },
    filtersMessages: {
      get () {
        return this.getFiltersMessages
      },
      set (value) {
        this.setMessages(value)
      }
    },
    filtersTendersPagination: {
      get () {
        return this.getFiltersTenders.pagination
      },
      set (value) {
        this.setTendersPagination(value)
      }
    },
    filtersTendersExternalPagination: {
      get () {
        return this.getFiltersTendersExternal.pagination
      },
      set (value) {
        this.setTendersExternalPagination(value)
      }
    },
    filtersTenderResultPagination: {
      get () {
        return this.getFiltersTenderResult.pagination
      },
      set (value) {
        this.setTenderResultPagination(value)
      }
    },
    filtersMessagesPagination: {
      get () {
        return this.getFiltersMessages.pagination
      },
      set (value) {
        this.setMessagesPagination(value)
      }
    },
    filtersToSyncro: {
      get () {
        return this.getFiltersToSyncro
      },
      set (value) {
        this.setToSyncro(value)
      }
    },
    filtersSelectedSyncro: {
      get () {
        return this.getSelectedSyncro.filter(disabled => (disabled.disableSelectRow === false && disabled.selectTemporaryRow === false))
      },
      set (value) {
        this.setSelectedSyncro(value)
      }
    }
  }
}
